import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyCzSaJ2Jh0F5GatnKgT8NXs48FMB0ZbKxA',
  authDomain: '',
  databaseURL: 'https://console.firebase.google.com/u/0/project/dhfmp3s',
  projectId: 'dhfmp3s',
  storageBucket: 'gs://dhfmp3s.appspot.com/',
  messagingSenderId: ''
}

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();

  }  
}
export default Firebase;

import React from "react";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  Chart,
  BarSeries,
  LineSeries,
  ArgumentAxis,
  ValueAxis,
  Legend
} from '@devexpress/dx-react-chart-material-ui';

// @material-ui/icons
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
// core components

import http from "../../../http";
import moment from "moment";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
const useStyles = makeStyles(styles);



export default function MusicTables(props) {
 const {type} = props;
 const classes = useStyles();
 const [nowPlaying, setNowPlaying] = React.useState([]);
 const [musicHub, setMusicHub] = React.useState([]);
 const [loading, setLoading] = React.useState(false);
 const [daily, setDaily] = React.useState([]);
const [monthly, setMontly] = React.useState([]);


React.useEffect(() => {
  setLoading(true);
  http.prototype.getAnalytics()
    .then(analy => {
      if(analy['daily']){
        setDaily(analy['daily'].map(e => {
          e.period = moment(e.createdAt).format('DD MMM YYYY')
          return e;
        }));
      }
      if(analy['monthly']){
        setMontly(analy['monthly'].map(e => {
          e.period = moment(e.createdAt).format('MMM YYYY')
          return e;
        }));
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    })
},[])

  return (
    <div >
    <h2 className={classes.title}> Daily Visits</h2>
        <Chart
          data={daily}
        >
        <ArgumentAxis />
        <ValueAxis />
          <BarSeries
            valueField="visits"
            argumentField="period"
          />
        </Chart>

    <h2 className={classes.title}> Monthly Visits</h2>

    <Chart
      data={monthly}
    >
    <ArgumentAxis />
    <ValueAxis />
      <LineSeries
        valueField="visits"
        argumentField="period"
      />
    </Chart>
      </div>
  );
}

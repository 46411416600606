/* eslint-disable import/no-duplicates */
/* eslint-disable handle-callback-err */
/* eslint-disable import/first */
/* eslint-disable prefer-promise-reject-errors */
import * as fb from 'firebase';
import  Firebase from "./configFirebase"
const firebase = new Firebase();
const firestore = firebase.db;
import { documentsHelper } from './Helpers/documentsHelper'
import Collections from './constants/Collections'
import Topics from './constants/Topics'
import Rules from './constants/Rules'
import Students from './constants/Students'
import Feed from './constants/Feed'
import Notifications from './constants/Notifications'
import Follow from './constants/Follow'
import Music from 'constants/Music'
import Users from 'constants/Users'
import Album from 'constants/Album';

export default class API {
 
  updateProfile (Id, update) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.STUDENTS)
        .doc(Id)
        .update({ ...update })
        .then(ee => {
          resolve(true)
        })
        .catch(err => {
          reject('Unable to perform action try again later')
        })
    })
  }
  getFirebaseStorage(){
    return firebase.storage;
  }

  // Topic Related
  getAllTopics () {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.TOPICS)
        .get()
        .then(docs => {
          if (docs.empty) {
            reject('No topics wore found')
          }
          const results = documentsHelper.getSnapshotsFromDocs(
            docs.docs,
            Topics
          )
          resolve(results)
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }
  getAllAmbassadors () {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.STUDENTS)
        .where(Students.ROLE, '==', 'Ambassador')
        .get()
        .then(docs => {
          if (docs.empty) {
            reject('No students wore found')
          }
          const results = documentsHelper.getSnapshotsFromDocs(
            docs.docs,
            Music
          )
          resolve(results)
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }
  
  getAllMusic () {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.MUSIC)
        .orderBy('createdAt','desc')
        .get()
        .then(docs => {
          const results = documentsHelper.getSnapshotsFromDocs(
            docs.docs,
            Music
          )
          resolve(results)
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }


  getAlbumById (id) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.ALBUM)
        .doc(id)
        .get()
        .then(docs => {
          if(docs.exists){
            const results = documentsHelper.getSnapshot(
              docs,
              Album
            )
            resolve(results)
          }else{
            resolve(null);
          }
       
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  getMusicById (id) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.MUSIC)
        .doc(id)
        .get()
        .then(docs => {
          if(docs.exists){
            const results = documentsHelper.getSnapshot(
              docs,
              Music
            )
            resolve(results)
          }else{
            resolve(null);
          }
       
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }


  getAllAlbums () {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.ALBUM)
        .orderBy('createdAt','desc')
        .get()
        .then(docs => {
          const results = documentsHelper.getSnapshotsFromDocs(
            docs.docs,
            Album
          )
          resolve(results)
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  addNewAlbum(music) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.ALBUM)
        .add({...music})
        .then(docs => {
          resolve(docs.id);
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }
  addNewAlbumFiles(id,music) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.ALBUM)
        .doc(id)
        .update({...music})
        .then(docs => {
          resolve(true);
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

    
  addNewMusic(music) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.MUSIC)
        .add({...music})
        .then(docs => {
          resolve(docs.id);
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  addNewMusicFiles(id,music) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.MUSIC)
        .doc(id)
        .update({...music})
        .then(docs => {
          resolve(true);
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  incrementDocumentField(collection,id,field) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(collection)
        .doc(id)
        .update({
          [field]: fb.firestore.FieldValue.increment(1)
        })
        .then(docs => {
          resolve(true);
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  deleteDocumentById(collection,id) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(collection)
        .doc(id)
        .delete()
        .then(docs => {
          resolve(true);
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  incrementDocumentFieldWithDate(collection,id,field) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(collection)
        .doc(id)
        .update({
          [field]: fb.firestore.FieldValue.increment(1),
          [`${field}_updatedAt`]: new Date(),
        })
        .then(docs => {
          resolve(true);
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  getAnalytics() {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.ANALYTICS)
        .get()
        .then(docs => {
          const results = documentsHelper.getAnalytics(
            docs.docs
          )
          resolve(results)
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  getMusicByType (type) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.MUSIC)
        .where(Music.TYPE, '==', type)
        .orderBy('createdAt','desc')
        .get()
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot.docs,
            Music
          )
          resolve(results)
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          console.log('err', err)
        })
    })
  }

  getRecentFeeds () {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.FEED)
        .orderBy(Feed.CREATED_AT, 'desc')
        .limit(60)
        .get()
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot.docs,
            Feed
          )
          resolve(results)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  getAllSearchedTopics (key) {
    return new Promise((resolve, reject) => {
      let query = firestore.collection(Collections.TOPICS)
      if (key.includes('user:')) {
        query = query.where(Topics.STUDENT_ID, '==', key.replace('user:', ''))
      } else if (key.includes('faculty:')) {
        query = query.where(Topics.FACULTY, '==', key.replace('faculty:', ''))
      } else {
        query = query.where(Topics.NAME, '>=', key)
      }

      query
        .get()
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot.docSnapshots,
            Topics
          )
          resolve(results)
        })
        .catch(err => {
          reject('Unable to retrieve topics at this moment', err)
        })
    })
  }

  getAllUserTopics (Id) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.TOPICS)
        .where(Topics.STUDENT_ID, '==', Id)
        .get()
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot.docSnapshots,
            Topics
          )
          resolve(results)
        })
        .catch(err => {
          reject('Unable to retrieve topics at this moment')
        })
    })
  }

  getFollowingUserTopics (following) {
    return new Promise((resolve, reject) => {
      this.getInQueryMultipleData(
        firestore.collection(Collections.TOPICS),
        Topics.TOPIC_ID,
        following)
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot,
            Topics
          )
          resolve(results)
        })
        .catch(err => {
          reject('Unable to retrieve topics at this moment')
        })
      console.log('passed')
    })
  }

  getTrendingTopics () {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.TOPICS)
        .orderBy(Topics.FOLLOWS, 'desc')
        .limit(10)
        .get()
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot.docSnapshots,
            Topics
          )
          resolve(results)
        })
        .catch(err => {
          reject('Unable to retrieve topics at this moment')
        })
    })
  }
  getInQueryMultipleData (query, field, array) {
    const promiseArray = []
    const chunks = documentsHelper.chunkArray(array, 10)
    chunks.map((chunk) => {
      promiseArray.push(
        query.where(field, 'in', chunk)
          .get()
      )
    })
    return Promise.all(promiseArray)
      .then((options) => {
        let results = []
        options.map(querySnapshoot => {
          results = results.concat(querySnapshoot.docSnapshots)
        })
        return results
      })
      .catch((error) => {
        console.log('error', error)
      })
  }
  getAllRelaventPosts (topicIds) {
    return new Promise((resolve, reject) => {
      return this.getInQueryMultipleData(
        firestore.collection(Collections.FEED), Feed.TOPIC_ID, topicIds)
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot,
            Feed
          )
          resolve(documentsHelper.sortByCreatedAt(results))
        })
        .catch(err => {
          reject('Unable to retrieve topics at this moment')
        })
    })
  }
  getAllTopicPosts (Id) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.FEED)
        .where(Feed.TOPIC_ID, '==', Id)
        .get()
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot.docSnapshots,
            Feed
          )
          resolve(documentsHelper.sortByCreatedAt(results))
        })
        .catch(err => {
          reject('Unable to retrieve topics at this moment')
        })
    })
  }

  setFollowingTopic (Id, mode, topicId, student) {
    return new Promise((resolve, reject) => {
      return firestore
        .collection(Collections.STUDENTS)
        .doc(Id)
        .update({
          [Students.FOLLOWING]: mode ? firestore.FieldValue.arrayUnion(Id) : firestore.FieldValue.arrayRemove(Id)
        })
        .then(ee => {
          const path = `${Collections.TOPICS}/${topicId}/${Collections.FOLLOW}`
          if (!mode) {
            return firestore
              .collection(path)
              .where(Follow.STUDENT_ID, '==', student[Follow.STUDENT_ID])
              .get()
          }
          return firestore
            .collection(path)
            .add({
              ...student
            })
        }).then(query => {
          if (!mode) {
            query.forEach(doc => {
              return doc.ref.delete()
            })
          }
          return resolve(true)
        })
        .catch(err => {
          reject('Unable to perform action try again later')
        })
    })
  }

  setLikePost (Id, studentId, like) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.FEED)
        .doc(Id)
        .update({
          [Feed.LIKED_BY]: !like ? firestore.FieldValue.arrayUnion(studentId) : firestore.FieldValue.arrayRemove(studentId)
        })
        .then(ee => {
          resolve(true)
        })
        .catch(err => {
          reject('Unable to perform action try again later')
        })
    })
  }
  getDocumentFromId (Id, Collection, Field) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collection)
        .doc(Id)
        .get()
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshot(querySnapshoot, Field)
          resolve(results)
        })
        .catch(err => {
          reject('Unable to perform operation at this moment')
        })
    })
  }

  deleteDocumentFromId (Id, Collection) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collection)
        .doc(Id)
        .delete()
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          reject('Unable to perform operation at this moment')
        })
    })
  }
  createTopic (topic) {
    return new Promise((resolve, reject) => {
      let id = null
      firestore
        .collection(Collections.TOPICS)
        .add({ ...topic })
        .then(item => {
          id = item.id
          return firestore
            .collection(Collections.TOPICS)
            .doc(id)
            .update({
              [Topics.TOPIC_ID]: id
            })
        })
        .then(() => {
          resolve(id)
        })
        .catch(err => {
          reject('Unable to create topics at this moment')
        })
    })
  }

  createComment (path, comment) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(path)
        .add({ ...comment })
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          reject('Unable to comment at this moment')
        })
    })
  }
  // Notification Related

  getAllStudentNotifications (Id) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.NOTIFICATION)
        .where(Notifications.STUDENT_ID, '==', Id)
        .get()
        .then(querySnapshoot => {
          const results = documentsHelper.getSnapshotsFromDocs(
            querySnapshoot.docSnapshots,
            Notifications
          )
          resolve(documentsHelper.sortByCreatedAt(results))
        })
        .catch(err => {
          reject('Unable to retrieve topics at this moment')
        })
    })
  }

  // Rules Related
  getAllRules () {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.RULES)
        .get()
        .then(doc => {
          if (doc.empty) {
            reject('No Rules found')
          }
          const snapshot = doc.docs[0]

          const results = documentsHelper.getSnapshot(snapshot, Rules)
          resolve(results)
        })
        .catch(err => {
          reject('Unable to retrieve rules at this moment')
        })
    })
  }

  // Login Related
  emailLinkRegister (user) {
    return new Promise((resolve, reject) => {
      firebase
        .createUser({
          email: user.email,
          password: user.password,
          phoneNumber: user.phone
        })
        .then(result => {
          const uid = result.uid
          user.uid = uid
          user.password = null
          return firestore.collection(Collections.STUDENTS).add({ ...user })
        })
        .then(item => {
          return firebase.sendEmailVerification()
        })
        .then(() => {
          return resolve(
            'succesfully registered your account, please verify your email address'
          )
        })
        .catch(err => {
          const alreadyExist = err.includes(
            'is already in use by another account'
          )
          if (alreadyExist) {
            return reject('Email address already in use')
          }
          return reject('Failed to register account ' + err)
        })
    })
  }

  getPostFromID (id) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.FEED)
        .doc(id)
        .get()
        .then(found => {
          const results = documentsHelper.getSnapshot(found, Feed)
          resolve(results)
        })
        .catch(err => {
          reject('Unable to retrieve rules at this moment')
        })
    })
  }
  // Rules Related
  getUserfromUid () {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.USERS)
        .get()
        .then(querySnapshoot => {
          if(querySnapshoot.empty){

          }else{

            const results = documentsHelper.getSnapshotsFromDocs(querySnapshoot.docs, Users)
            resolve(results)
          }
        
   
        })
        .catch(err => {
          reject('Unable to retrieve rules at this moment', err)
        })
    })
  }
  emailLinkLogin (emailAddress, password) {
    return new Promise((resolve, reject) => {
      firebase
        .auth
        .signInWithEmailAndPassword(
          emailAddress,
          password
        )
        .then(result => {
          result = result.user
          const uid = result.uid
          resolve(uid)
        })
        .catch(err => {
          reject('failed to login user ' + err)
        })
    })
  }
  // Device token Related
  updateDeviceTokenForUser (id, token) {
    return new Promise((resolve, reject) => {
      firestore
        .collection(Collections.STUDENTS)
        .doc(id)
        .update({
          deviceToken: token
        })
    })
  }
}

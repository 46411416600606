import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import Provider from "react-redux/es/components/Provider";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import store from "./store/index";

// pages for this product
import Components from "views/Components/Components.js";
import AdminPage from "views/Components/Admin";
import AdminHandler from "views/Components/AdminHandler";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import MusicDownload from "views/Components/MusicDownload.js";
import AlbumDownload from "views/Components/AlbumDownload.js";
import http from "./http";
import moment from "moment";

var hist = createBrowserHistory();

http.prototype.incrementDocumentField('Analytics','daily',moment().format("DD_MM_YYYY"));
http.prototype.incrementDocumentField('Analytics','monthly',moment().format("MM_YYYY"));

ReactDOM.render(
  <Provider store={store}>
  <Router history={hist}>
    <Switch>
      <Route path="/admin" component={AdminPage} />
      <Route path="/admin-handler" component={AdminHandler} />
      <Route path="/music/downloads/:id" component={MusicDownload} />
      <Route path="/album/downloads/:id" component={AlbumDownload} />
      <Route path="/about" component={ProfilePage} />
      <Route path="/login" component={LoginPage} />
 
      <Route path="/view/:page" component={Components} />
      <Route path="/" component={Components} />
     </Switch>
  </Router>
  </Provider>,
  document.getElementById("root")
);

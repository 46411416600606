/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
    <ListItem className={classes.listItem}>
      <Link to="/"   className={classes.navLink}>
      Home
    </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Link to="/view/Mixtapes/"   className={classes.navLink}>
      Mixtapes
    </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Link to="/view/Acapella/"   className={classes.navLink}>
      Acapella
    </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Link to="/view/Podcasts/"   className={classes.navLink}>
      Podcasts
    </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Link to="/view/Albums/"   className={classes.navLink}>
      Albums
    </Link>
      </ListItem>



      <ListItem  className={classes.listItem}>
      <Link to="/about/"   className={classes.navLink}>
      About
    </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Link to="/login/"   className={classes.navLink}>
      Login
    </Link>
      </ListItem>
    </List>
  );
}

import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import http from "../../http";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
const useStyles = makeStyles(styles);



export default function Components(props) {
  const history = useHistory()
  const logged = useSelector(store => store.main.state);
  const classes = useStyles();

 
setTimeout(() => {
  
  if(!logged){
    history.push('/login');
  }else{
    history.push('/admin');
  }
}, 10);

React.useEffect(() => {
  http.prototype.getAnalytics()
},[])
  return (
    <div align="center">
    <h2 className={classes.title}> Please Wait</h2>
    <LinearProgress color="primary" />
    </div>
  );
}

export const LOGIN_USER = 'LOGIN USER';


export function loginUser(user)
{
    return {
        type: LOGIN_USER,
        payload: user
    }
}


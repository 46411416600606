import React from "react";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from "@material-ui/core/LinearProgress";
// @material-ui/icons
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
// core components

import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import { useHistory } from "react-router-dom";
import http from "../../../http";
import moment from "moment";
import Collections from "constants/Collections";
import Music from "constants/Music";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import artImage  from "../../../assets/img/art.jpg";



const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
});





export default function MusicTables(props) {
 const {type} = props;
 const classes = useStyles();
 const [nowPlaying, setNowPlaying] = React.useState([]);
 const [musicHub, setMusicHub] = React.useState([]);
 const [loading, setLoading] = React.useState(false);
 const history = useHistory();


 const [showPlayer, setShowplayer] = React.useState(false); 

React.useEffect(() => {
  setLoading(true);
  http.prototype.getAllAlbums()
    .then(music => {
      setMusicHub(music);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    })
},[])

const onListenHandler = React.useCallback((id)=>{
  http.prototype.incrementDocumentFieldWithDate('Music',id,'views');
},[])
  return (
    <div >
    {loading && <LinearProgress color="primary" />}
    <GridContainer>
    {musicHub.map((row, index) => {
      return <GridItem key={index} xs={12} sm={6} md={6}>
      <Card style={{maxWidth:340, marginTop: 10}} >
      <CardActionArea>
        <CardMedia
          component="img"
          alt="img"
          height="300"
          width="340"
          image={row.imageUrl || artImage}
          title="Album Image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {row.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
             {row.artist}
          </Typography>
          <Typography variant="caption"  style={{color:'rgb(40,51,79)'}} color="inherit" component="p">
          {moment(row.createdAt.toDate()).format('DD MMM, YYYY')}
       </Typography>
          <Typography variant="caption" color="primary" component="p">
          {row.songs.length} Songs
       </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      
        <Button size="small" onClick={()=> {history.push(`/album/downloads/${row.Id}`)}}  color="primary">
          Download
        </Button>
      </CardActions>
    </Card>

      </GridItem>
    })}
    </GridContainer>

      </div>
  );
}

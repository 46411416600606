const Notifications = {
    NAME  : 'name',
    NOTIFICATION : 'notification',
    LIKE: 'like',
    PROFILE_URL: 'profileUrl',
    STUDENT_ID:'studentID',
    TEXT:'text',
    TYPE:'type',
    TYPE_ID:'typeID',
    CREATED_AT:'createdAt',
}

export default Notifications;
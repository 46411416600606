import React from "react";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles,useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import People from "@material-ui/icons/People";
import Check from "@material-ui/icons/Check";
import Download from "@material-ui/icons/GetApp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import http from "../../../http";
import MusicTables from "./MusicTables"
import AlbumTables from "./AlbumTables"
import LinearProgress from "@material-ui/core/LinearProgress";
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import Button from '@material-ui/core/Button';
import artImage  from "../../../assets/img/art.jpg";
import moment from "moment";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop:20
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width:'80%'
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '120px',
    alignItems: 'right',
    height:'120px',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function SectionBasics(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const [loading, setLoading] = React.useState(false);
  const {musicId} = props;
  const [music, setMusic] = React.useState({});
  const theme = useTheme();


  React.useEffect(() => {
    setLoading(true);
    http.prototype.getMusicById(musicId)
      .then(music => {
       // setMusicHub(music);
       setMusic(music);
        setLoading(false);
      
      })
      .catch(error => {
        console.log('error',error);
        setLoading(false);
      })
  },[])

 const sendDownload =  React.useCallback((music) => {
    http.prototype.incrementDocumentField('Music',music.Id,'downloads');
  },[])


  return (
    <div className={classes.sections}>
      <div style={{minHeight:600}} className={classes.container}>
      {loading && <LinearProgress color="primary" />}
      <GridContainer>
      <GridItem xs={12} sm={12} md={10} lg={10}>
    {music.name &&  <Card className={classes2.root}>
      <div className={classes2.details}>
        <CardContent className={classes2.content}>
          <Typography  variant="h5">
            {music.name}
          </Typography>
          <Typography  variant="body1" color="error">
           {music.artist}
          </Typography>
          <Typography variant="caption"  style={{color:'rgb(40,51,79)'}} color="inherit" component="p">
          {moment(music.createdAt.toDate()).format('DD MMM, YYYY')}
       </Typography>
          <Typography  variant="caption"  className="pr-2" color="primary">
          {music.downloads} Downloads
          </Typography>
         
          <Typography  variant="caption" color="inherit">
          Played {music.views} times
         </Typography>
        </CardContent>
        <div className={classes2.controls}>
          
         
        </div>
      </div>
      <CardMedia
        className={classes2.cover}
        image={music.imageUrl || artImage}
        title="Live from space album cover"
      />
    </Card>}
      </GridItem>
      {music.fileSize && <GridItem style={{paddingTop:5}}  xs={12} sm={12} md={12} lg={12} >Download Size: {music.fileSize} </GridItem>}

      <GridItem xs={12} sm={12} md={12} lg={12} >
      
   {music.name &&   <a  href={music.musicUrl} target="_blank"  download="netshedzo.mp3">
   <Button
   variant="contained"
   color="primary"
   onClick={() => {sendDownload(music)}}
   style={{margin:theme.spacing(2)}}
  startIcon={<Download />}
 >
   Download File
 </Button>
</a>}
      </GridItem>
    </GridContainer>
      </div>
      </div>
  );
}

const Music = {
    NAME  : 'name',
    ARTIST: 'artist',
    TIME: 'time',
    GENRE:'genre',
    CREATED_AT:'createdAt',
    IMAGE_URL: 'imageUrl',
    MUSIC_URL: 'musicUrl',
    TYPE: 'type',
    VIEWS: 'views',
    VIEWS_UPDATED_AT:'views_updatedAt',
    DOWNLOADS: 'downloads',
    DOWNLOADS_UPDATED_AT:'downloads_updatedAt',
    FILE_SIZE:'fileSize',
    FILE_NAME:'fileName',
}

export default Music;
import React from "react";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import MusicTables from "./MusicTables"
import AlbumTables from "./AlbumTables"
const useStyles = makeStyles(styles);

export default function SectionBasics(props) {
  const classes = useStyles();
  const {page} = props;
  const currentPage = page || 'Single';

  return (
    <div className={classes.sections}>
      <div style={{minHeight:600}} className={classes.container}>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={10}>
      {(currentPage ==='Single') &&    <MusicTables type="Single" /> }
      {currentPage ==='Mixtapes' &&    <MusicTables type="Mixtape" /> }
      {currentPage ==='Podcasts' &&    <MusicTables type="Podcast" /> }
      {currentPage ==='Acapella' &&    <MusicTables type="Acapella" /> }
      {currentPage ==='Albums' &&     <AlbumTables /> }
      </GridItem>
    </GridContainer>
      </div>
      </div>
  );
}

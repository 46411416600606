import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/profileLogo.jpg";

import studio1 from "assets/img/profile1.jpg";
import studio2 from "assets/img/profile2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/profile3.jpg";
import studio5 from "assets/img/profile4.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg4.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Ndou Livhuwani Da Gifto</h3>
                    <h6>Founder</h6>
                    <Button justIcon  href="https://twitter.com/GiftoMr?s=08" link className={classes.margin5}>
                      <i className={"fab fa-twitter"}  />
                    </Button>
                    <Button justIcon    href="https://www.instagram.com/deephouseforest" link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link href="https://web.facebook.com/giftdagifto.ndou.3" className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
              Dj & Producer.
              Deep House Forest founder.
              For bookings: 0643488935
              Email: Dagiftosa@gmail.com/ Deephouseforest75@gmail.com
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
              <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <img
                  alt="..."
                  src={studio1}
                  className={navImageClasses}
                />
                <img
                  alt="..."
                  src={studio2}
                  className={navImageClasses}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <img
                  alt="..."
                  src={studio5}
                  className={navImageClasses}
                />
                <img
                  alt="..."
                  src={studio4}
                  className={navImageClasses}
                />
              </GridItem>
            </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

import * as Actions from '../../actions/main.actions';

const initialState = {
    state: false,
    user  : {},
};

const main = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.LOGIN_USER:
        {
            return {
                state  : true,
                user: action.payload
            };
        }
        default:
        {
            return state;
        }
    }
};

export default main;
const Students = {
    NAME  : 'name',
    SURNAME: 'surname',
    EMAIL: 'email' ,
    FACULTY: 'faculty',
    UID:'uid',
    POINTS:'points',
    PROFILE_IMAGE:'profileImage',
    FOLLOWING: 'following',
    OWNER: 'owner',
    PHONE_NUMBER: 'phoneNumber',
    STUDENT_FOLLOWERS:'studentFollowers',
    STUDENT_FOLLOWING:'studentFollowing',
    FOLLOWER_COUNT:  'followerCount',
    ROLE: 'role',
    ABOUT:'about'
}

export default Students;
const Topics = {
    NAME  : 'name',
    DESCRIBE: 'description',
    FOLLOWS: 'follows',
    COLOR: 'color',
    STUDENT_ID:'studentID',
    TOPIC_ID:'topicId',
    FACULTY: 'faculty',
}

export default Topics;
const Feed = {
    POST: 'post',
    CREATED_AT: 'createdAt',
    LIKES: 'likes' ,
    PHOTOS: 'photos',
    TOPIC_ID: 'topicID',
    COMMENTS: 'comments',
    LIKED_BY: 'likedBy',
    TOPIC_NAME:'topicName',
    TOPIC_REF:'topicRef',
    FACULTY:'faculty',
    LINK: 'link',

}

export default Feed;
import React from "react";
import clsx from 'clsx';
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from "@material-ui/core/LinearProgress";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SearchIcon from '@material-ui/icons/SearchOutlined';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import artImage  from "../../../assets/img/art.jpg";
// @material-ui/icons
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import DownloadIcom from '@material-ui/icons/GetApp';
import { useHistory } from "react-router-dom";
// core components

import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import "../../../assets/css/tailwind.css";
import http from "../../../http";
import moment from "moment";
import Collections from "constants/Collections";
import Music from "constants/Music";


const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
});





export default function MusicTables(props) {
 const {type} = props;
 const classes = useStyles();
 const history = useHistory()
 const [nowPlaying, setNowPlaying] = React.useState([]);
 const [musicHub, setMusicHub] = React.useState([]);
 const [loading, setLoading] = React.useState(false);
 const [search, setSearch] = React.useState('');

 const [showPlayer, setShowplayer] = React.useState(false); 

React.useEffect(() => {
  setLoading(true);
  http.prototype.getMusicByType(type)
    .then(music => {
      setMusicHub(music);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    })
},[])

const onListenHandler = React.useCallback((id)=>{
  http.prototype.incrementDocumentFieldWithDate('Music',id,'views');
},[])
  return (
    <div >
    <GridContainer>
    <GridItem xs={12} sm={12} md={7}>
    <FormControl className="w-full flex justify-center text-center" >
    <InputLabel className="text-center" htmlFor="standard-adornment-password">Search Music</InputLabel>
    <Input
      id="standard-adornment-password"
      value={search}
      onChange={(e)=>{setSearch(e.target.value)} }
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon  style={{color:'rgb(40,51,79)'}} color="primary"/>
        </InputAdornment>
      }
    />
  </FormControl>
    </GridItem>

    {musicHub.filter(e => e.name.toLowerCase().includes(search.toLowerCase())).map((row, index) => {
      return <GridItem key={index} xs={12} sm={6} md={6}>
      <Card style={{maxWidth:340, marginTop: 10}} >
      <CardActionArea>
        <CardMedia
          component="img"
          alt="img"
          height="300"
          width="340"
          image={row.imageUrl || artImage}
          title="Album Image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {row.name}
          </Typography>
           <div className="flex justify-between">
           <Typography variant="body2" color="textSecondary" component="p">
           {row.artist}
        </Typography>
        <Typography variant="caption" className="  px-2" style={{backgroundColor:'rgb(40,51,79)', color:'ghostwhite',borderRadius:10, height:20}} color="inherit" component="p">
        {row.time}
     </Typography>
           </div>
          <Typography variant="caption" color="primary" component="p">
          {row.genre} 
       </Typography>
       <Typography variant="caption"  style={{color:'rgb(40,51,79)'}} color="inherit" component="p">
       {moment(row.createdAt.toDate()).format('DD MMM, YYYY')}
    </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <Button style={{color:'rgb(40,51,79)'}} startIcon={<PlayCircleFilledIcon/>} size="small"  onClick={() => {
        setShowplayer(false);
        setTimeout(() => {
          const time = row.time;

          const tim = (Number(time.split(':')[0])*60) + Number(time.split(':')[1]);
          setNowPlaying(
            [{
              name: row.name,
              musicSrc: row.musicUrl,
              cover: row.imageUrl,
              singer: row.artist,
              duration: tim,
            }]
          )
          onListenHandler(row.Id);
          setShowplayer(true);
        }, 1000)
      }}  color="primary">
      Play
    </Button>
      
        <Button style={{color:'rgb(40,51,79)'}} startIcon={<DownloadIcom />} size="small" onClick={()=> {history.push(`/music/downloads/${row.Id}`)}}  color="primary">
          Download
        </Button>
      </CardActions>
    </Card>

      </GridItem>
    })}
    </GridContainer>

  {showPlayer && <ReactJkMusicPlayer  responsive={false}  audioLists={nowPlaying} toggleMode={false}  showDestroy remove showPlay showLyric={false} autoPlay showThemeSwitch={false} showDownload={false}  mode="full"  />}
      </div>
  );
}

import React from "react";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import People from "@material-ui/icons/People";
import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Paginations from "components/Pagination/Pagination.js";
import Badge from "components/Badge/Badge.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import NavPills from "components/NavPills/NavPills.js";
import MusicIcon from "@material-ui/icons/MusicNote";
import AlbumIcon from "@material-ui/icons/Album";
import MixIcon from "@material-ui/icons/LibraryMusic";
import MusicAddIcon from "@material-ui/icons/AddCircle";
import AlbumAddIcon from "@material-ui/icons/AddBox";
import PodIcon from "@material-ui/icons/MusicNoteTwoTone";
import TimelineIcon from "@material-ui/icons/Timeline";
import Analytics from "./AnalyticSection"
import MusicAdmin from "./MusicTablesAdmin"
import AlbumAdmin from "./AlbumTablesAdmin"
import NewMusic from "./NewMusic"
import NewAlbum from "./NewAlbum"
const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const classes = useStyles();

  return (
    <div className={classes.sections}>
      <div style={{minHeight:600}} className={classes.container}>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <NavPills
          color="primary"
          horizontal={{
            tabsGrid: { xs: 12, sm: 12, md: 3 },
            contentGrid: { xs: 12, sm: 12, md: 9 }
          }}
          tabs={[
            {
              tabButton: "Analytics",
              tabIcon: TimelineIcon,
              tabContent: (
                <Analytics />
              )
            },
            {
              tabButton: "Music",
              tabIcon: MusicIcon,
              tabContent: (
                <MusicAdmin />
              )
            },
            {
              tabButton: "New Music",
              tabIcon: MusicAddIcon,
              tabContent: (
               <NewMusic />
              )
            },
            {
              tabButton: "Albums",
              tabIcon: AlbumIcon,
              tabContent: (
                <AlbumAdmin />
              )
            },
            {
              tabButton: "New Album",
              tabIcon: AlbumAddIcon,
              tabContent: (
                <NewAlbum />
              )
            },
          ]}
        />
      </GridItem>
    </GridContainer>
      </div>
      </div>
  );
}

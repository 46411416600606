import React from "react";
import clsx from 'clsx';
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from "@material-ui/core/LinearProgress";

// @material-ui/icons
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import EditIcon from '@material-ui/icons/EditOutlined';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import IconButton from '@material-ui/core/IconButton';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components

import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import http from "../../../http";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
}));




const storage = http.prototype.getFirebaseStorage();

export default function MusicTables(props) {
 const {type} = props;
 const classes = useStyles();
 const [nowPlaying, setNowPlaying] = React.useState([]);
 const [musicHub, setMusicHub] = React.useState([]);
 const [loading, setLoading] = React.useState(false);
 const [search, setSearch] = React.useState('');
 const [current, setCurrent] = React.useState({});
 const [downloads, setDownloads] = React.useState(0);


 const [showPlayer, setShowplayer] = React.useState(false); 

React.useEffect(() => {
  setLoading(true);
  http.prototype.getAllMusic()
    .then(music => {
      setMusicHub(music);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    })
},[]);

const handleCurrentChange = (prop) => (event) => {
  setCurrent({ ...current, [prop]: event.target.value });
};


const refreshHandler = React.useCallback(() => {
  setLoading(true);
  http.prototype.getAllMusic()
    .then(music => {
      setMusicHub(music);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    })
},[]);

const updateDownloads = React.useCallback((current,downloads) => {
  setLoading(true);
  http.prototype.addNewMusicFiles(current.Id,{downloads: Number(downloads)})
    .then(music => {
      setCurrent({});
      refreshHandler();
    }).catch(error => {
      setLoading(false);
    })
},[]);

const deleteFile = React.useCallback((path) => {
  const fileTask = storage.ref().child(path)
  return fileTask.delete();
 },[]);

  
const deleteFiles = React.useCallback((fileUrl, id, hasImage) => {
  setLoading(true);
   const promiseArray = [];
 

   promiseArray.push(http.prototype.deleteDocumentFromId(id, 'Music'))
   if(fileUrl){
  
    deleteFile(`music/${fileUrl}`);
   }
   if(hasImage){
    deleteFile(`images/${id}.jpg`);
  }
  
    Promise.all(promiseArray)
    .then(music => {
      refreshHandler();
    }).catch(error => {
      setLoading(false);
    })
},[]);

  return (
    <div >
    <GridContainer>
    <GridItem xs={10} sm={10} md={10}>
    <FormControl className={clsx(classes.margin, classes.textField)}>
    <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
    <Input
      id="standard-adornment-password"
      value={search}
      onChange={(e)=>{setSearch(e.target.value)} }
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon  color="primary"/>
        </InputAdornment>
      }
    />
  </FormControl>
    </GridItem>
    <GridItem xs={2} sm={2} md={2}>
    <IconButton onClick={refreshHandler} >
     <RefreshIcon />
    </IconButton>
    </GridItem>
    <GridItem xs={12} sm={12} md={12}>
      {current.name && <Paper>
        <h5 color="primary">Editing: {current.name}</h5>
        <CustomInput
        labelText="Downloads"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: (event) => {
          setDownloads(event.target.value);
          },
          value: downloads
        }}
      />
      <Button onClick={() => {updateDownloads(current,downloads)}} color="primary">Update</Button>
      <Button onClick={()=> {setCurrent({})}} color="primary">Hide</Button>
        </Paper>}
    </GridItem>
    </GridContainer>
    <TableContainer component={Paper}>
    <Table className={classes.table} size="medium"  aria-label="a dense table">
      <TableHead>
        <TableRow >
         <TableCell style={{color:'#23304F'}}  align="center"></TableCell>
         <TableCell style={{color:'#23304F'}} align="center"></TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Name</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Time</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Artist</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Genre</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Type</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Date</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Views</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Last Viewed</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Downloads</TableCell>
          <TableCell style={{color:'#23304F'}} align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {musicHub.filter(e => e.name.toLowerCase().includes(search.toLowerCase())).map((row,index) => (
          <TableRow key={index}>
          <TableCell color="primary"  align="center">
          <Avatar src={row.imageUrl} alt="e"  sizes="15 15" variant="rounded" />
          </TableCell>
          <TableCell color="primary" onClick={() => {
            setShowplayer(false);
            setTimeout(() => {
              setNowPlaying(
                [{
                  name: row.name,
                  musicSrc: row.musicUrl,
                  cover: row.imageUrl,
                  singer: row.artist,
                  duration: 602,
                }]
              )
              setShowplayer(true);
            }, 1000)
          }}  align="center">
          <PlayCircleFilledIcon  style={{color:'#23304F'}}  />
          </TableCell>
            <TableCell  style={{color:'#23304F'}} component="th" scope="row" align="center">
              {row.name}
            </TableCell>
            <TableCell style={{color:'#23304F'}} align="center">{row.time}</TableCell>
            <TableCell style={{color:'#23304F'}} align="center">{row.artist}</TableCell>
            <TableCell style={{color:'#23304F'}} align="center">{row.genre}</TableCell>
            <TableCell style={{color:'#23304F'}} align="center">{row.type}</TableCell>
            <TableCell style={{color:'#23304F'}} align="center">{moment(row.createdAt.toDate()).format("DD/MM/YYYY")}</TableCell>
            <TableCell style={{color:'#23304F'}} align="center">{row.views}</TableCell>
            <TableCell style={{color:'#23304F'}} align="center">{row.views_updatedAt ? moment(row.views_updatedAt.toDate()).format('DD/MM/YYYY') : 'N/A'}</TableCell>
            <TableCell style={{color:'#23304F'}} align="center">{row.downloads}</TableCell>
            <TableCell  style={{color:'#23304F'}} align="center">
          
             <EditIcon onClick={()=> {
              setCurrent(row);
              setDownloads(row.downloads);
            }}   />
            <DeleteIcon onDoubleClick={()=> {deleteFiles(row.fileName,row.Id,row.imageUrl)}}  />
         
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    {loading && <LinearProgress color="primary" />}
  </TableContainer>
  {showPlayer && <ReactJkMusicPlayer   audioLists={nowPlaying} toggleMode={false}  showDestroy remove showPlay showLyric={false} autoPlay showThemeSwitch={false} showDownload={false}  mode="full"  />}
      </div>
  );
}

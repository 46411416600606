
function getSnapshot (document, snapConstant) {
  const model = {}
  model.Id = document.id
  document = document.data()
  const keys = Object.keys(snapConstant)

  keys.map(key => {
    model[snapConstant[key]] = document[snapConstant[key]]
  })

  return model
}
function getCreatedAtFromAnalytics(key){
let found = key.split('_');
let dateString = '';
if(found.length === 2){
  dateString = `${found[0]}/01/${found[1]}`
}
if(found.length === 3){
  dateString = `${found[1]}/${found[0]}/${found[2]}` 
}
 return new Date(dateString);
}

function getAnalyticsData (document) {
  const model = []
  document = document.data()
  Object.keys(document).map(key => {
    if(key.includes('_')){
      model.push({period: key, visits:document[key],createdAt:getCreatedAtFromAnalytics(key)})
    }
  })
  return model
}

function getSnapshotsFromDocs (documents, snapConstant) {
  const docs = documents
  const snaps = docs.map(snapshot => {
    return getSnapshot(snapshot, snapConstant)
  })
  return snaps
}

function getAnalytics (documents) {
  const docs = documents
  const snaps = {};
   docs.map(snapshot => {
    snaps[snapshot.id] = sortByCreatedAtAsc(getAnalyticsData(snapshot));
  })
  return snaps
}

function sortByCreatedAt (items) {
  const sortedItems = items.sort(function (itemA, itemB) {
    const a = new Date(itemA.createdAt)
    const b = new Date(itemB.createdAt)
    if (a < b) { return 1 }
    if (a > b) { return -1 }

    return 0
  })
  return sortedItems
}

function sortByCreatedAtAsc (items) {
  const sortedItems = items.sort(function (itemA, itemB) {
    let a = new Date(itemA.createdAt)
    let b = new Date(itemB.createdAt)
    if (a > b) { return 1 }
    if (a < b) { return -1 }

    return 0
  })
  return sortedItems
}

function chunkArray (myArray, chunkSize) {
  let tempArray = []

  tempArray = Array(Math.ceil(myArray.length / chunkSize)).fill().map(function (_, i) {
    return myArray.slice(i * chunkSize, i * chunkSize + chunkSize)
  })

  return tempArray
}

export const documentsHelper = {
  getSnapshot,
  getSnapshotsFromDocs,
  sortByCreatedAt,
  sortByCreatedAtAsc,
  chunkArray,
  getAnalytics
}

const Collections = {
    STUDENTS : 'Students',
    TOPICS: 'Topics',
    COLORS: 'Colors',
    FEED: 'Feed',
    COMMENT:'Comment',
    LIKE: 'Like',
    RULES: 'Rules',
    NOTIFICATION: 'Notifications',
    FOLLOW: 'Follow',
    MUSIC: 'Music',
    ALBUM:'Album',
    USERS:'Users',
    ANALYTICS:'Analytics',
}

export default Collections;
import React from "react";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
// @material-ui/icons
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
// core components
import NativeSelect from '@material-ui/core/NativeSelect';
import http from "../../../http";
import moment from "moment";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useDropzone } from "react-dropzone";


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);


const storage = http.prototype.getFirebaseStorage();

const styles = {
  textCenter: {
    textAlign: "center"
  },
  textMuted: {
    backgroundColor: "green"
  },
};
const musicDropStyle = {
  fontWeight: 'bold',
  border: 4,
  borderColor: 'ghostwhite',
  backgroundColor: 'rgb(35,48,79)',
  padding: 30,
  color:'ghostwhite'
}
const useStyles = makeStyles(styles);
const filesize = require('filesize');
export default function AddMusic(props) {
 const [loading, setLoading] = React.useState(false);
 const classes = useStyles();
 const [music, setMusic] = React.useState({
        'name' : '',
        'artist': '',
        'time': '',
        'genre': '',
        'createdAt': new Date(),
        'imageUrl':'',
        'musicUrl':'',
        'type':'',
        'views':0,
        'downloads':0,
        fileSize:'',
        fileName:'',
 });
 const [musicImage, setMusicImage] = React.useState({});
 const [image, setImage] = React.useState('');
 const [file, setFile] = React.useState({});
 const [progress, setProgress] = React.useState(0);
 const [aploading, setAploading] = React.useState('');
 function getBase64(file) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
      setImage(reader.result);
  };
  reader.onerror = function (error) {
  };
}
  const resetState =  () => {
   setMusic(
    {
      'name' : '',
      'artist': '',
      'time': '',
      'genre': '',
      'createdAt': new Date(),
      'imageUrl':'',
      'musicUrl':'',
      'type':'',
      'views':0,
      'downloads':0,
      fileSize:'',
      fileName:'',
}
   )
  };
 const onDrop = React.useCallback(acceptedFiles => {
  const promiseArray = [];
  const file =  acceptedFiles[0];
  if(file.type.includes('image')){
   setMusicImage(file);
   getBase64(file);
  }else{
    setFile(file);
    setMusic(e=> {
      e['fileName']='dhfmp3s'+file.name;
      e['fileSize'] = filesize(file.size, {round: 1,exponent:2});
      return e;
    });
  }
 });
  

 const handleMusicChange = (key, value) => {
   const mus = {...music};
   mus[key] = value;
   setMusic(mus);
 }

 const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop
});

const aploadFile = React.useCallback((path,file, id, key) => {
 const fileTask = storage.ref().child(path).put(file)
 return fileTask.on('state_changed', function(snapshot){
  var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  setProgress(progress);
}, function(error) {
}, function() {
  return fileTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
    const path = {[key]: downloadURL};

    if(key === 'musicUrl'){
      setAploading('');
      setFile({});
      setImage('');
    }
   return http.prototype.addNewMusicFiles(id, path);
  });
});

},[]);

const addNewMusc = React.useCallback((music,musicImage,file) => {

  let foundId = '';
  return http.prototype.addNewMusic(music)
    .then(id => {
     setAploading('Aploading Song ');
     foundId = id;
     if(musicImage.name){
      return aploadFile(`images/${id}.jpg`,musicImage,id,'imageUrl')
     }else{
       return true;
     } 
    }).then(url => {
      return aploadFile(`music/dhfmp3s${file.name}`,file,foundId,'musicUrl')
    }).then(()=> {
      resetState();
    })
},[]);

  return (
    <div >
    <Card classes={classes.textCenter} >
    <CardHeader color="primary" >New Music</CardHeader>
    <CardBody>
    <CustomInput
    labelText="Song Name"
    formControlProps={{
      fullWidth: true
    }}
    inputProps={{
      onChange: (event) => {
       handleMusicChange('name',event.target.value);
      },
      value: music.name
    }}
  />
  <CustomInput
  labelText="Song Artist"
  formControlProps={{
    fullWidth: true
  }}
  inputProps={{
    onChange: (event) => {
     handleMusicChange('artist',event.target.value);
    },
    value: music.artist
  }}
/>
<GridContainer>
<GridItem xs={12} sm={12} md={6}>
<NativeSelect
color="primary"
style={{width:'100%'}}
value={music.genre}
onChange={(event) => {
  handleMusicChange('genre',event.target.value);
}}
inputProps={{
  name: 'Select Genre',
  id: 'age-native-helper',
}}
>
<option defaultValue value="Select Song Genre" >Select Song Genre</option>
{['Afro House','Deep House',
  'Soulful House',
  'Afro pop',
  'Afro Soul',
  'Nostalgic Deep','Amapiano'].map(e => <option key={e} value={e} >{e}</option>)}
</NativeSelect>

</GridItem>
<GridItem xs={12} sm={12} md={6}>
<NativeSelect
color="primary"
style={{width:'100%'}}
value={music.type}
onChange={(event) => {
  handleMusicChange('type',event.target.value);
}}
inputProps={{
  name: 'Select Song Genre',
  id: 'age-native-helper',
}}
>
<option defaultValue value="Select Song Type" >Select Song Type</option>
{['Single',
  'Mixtape',
  'Podcast',
  'Acapella'].map(e => <option key={e} value={e} >{e}</option>)}
</NativeSelect>

</GridItem>

<GridItem xs={12} sm={12} md={12}>
<CustomInput
labelText="Enter Song Time 00:00"
formControlProps={{
  fullWidth: true
}}
inputProps={{
  onChange: (event) => {
   handleMusicChange('time',event.target.value);
  },
  value: music.time
}}
/>

</GridItem>
<GridItem xs={12} sm={12} md={12}>
{image && <Avatar src={image} style={{width:150,height:150}} variant="rounded" />}
<h6 color="primary">Image File: {musicImage.name} </h6>
<h6  color="primary">Music File: {file.name} </h6>
<div
style={musicDropStyle}
{...getRootProps()}
>
<input {...getInputProps()} />
{isDragActive ? (
  <p>Drop the The Song File or Image Here ...</p>
) : (
  <p>Drag 'n' drop some files here, or click to Select Music or Image</p>
)}
</div>
</GridItem>
<GridItem xs={12} sm={12} md={12}>
<br />
{aploading && <h6>{aploading}</h6>}
{aploading && <BorderLinearProgress variant="determinate" color="primary" value={progress} />}

<br />
<Button onClick={()=> {addNewMusc(music,musicImage,file)}} color="primary">Add Song</Button>
</GridItem>
</GridContainer>


    </CardBody>
  </Card>
      </div>
  );
}
